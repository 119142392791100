<template>
  <v-container fluid>
    <v-card>
      <v-card-text class="ma-0 pa-0">
        <v-tabs left v-model="tabs">
          <v-tab>
            {{ $t('erp.lang_baseData') }}
          </v-tab>

          <v-tab>
            {{ this.$t('erp.lang_productImage') }}
          </v-tab>
          <v-tab>
            {{ this.$t('erp.lang_warecreate_wareattr') }}
          </v-tab>
        </v-tabs>

        <v-form ref="form">
          <v-tabs-items class="pa-2" v-model="tabs">
            <v-tab-item class="pa-3">
              <v-form lazy-validation ref="general">
                <v-row>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  @focus="showTouchKeyboard"
                                  :label="this.$t('erp.lang_ware_create_id')"
                                  @change="checkEAN"
                                  @blur="checkEAN"
                                  :rules="[v => !!v  , this.duplicatedEAN || $t('erp.lang_barcodeTaken')]"
                                  :loading="loadingCheck"
                                  autofocus
                                  outlined
                                  v-model="form.ware_ID">
                      <v-btn :disabled="this.loadingEAN" :loading="this.loadingEAN"
                             @click="createEAN"
                             class="ma-0 pa-0" icon slot="append"
                             style="margin: -4px -6px 0 0 !important">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <div style="margin-top:-5px;">
                              <font-awesome-icon :icon="['fal' , 'barcode']"
                                                 class="primary--text ma-0"
                                                 size="2x"
                                                 v-on="on"/>
                            </div>
                          </template>
                          <span>{{ $t('generic.lang_edit') }}</span>
                        </v-tooltip>
                      </v-btn>

                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" sm="6">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.lang_posItemName')" :rules="[v => !!v]"

                                  outlined
                                  v-model="form.ware_name"/>
                  </v-col>

                  <v-col cols="12" md="4" sm="4">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.internal_wareNo')"
                                  :loading="loadingCheckInternID"
                                  :rules="[ this.duplicatedInternID || $t('erp.lang_duplicatedInternalId')]"
                                  @change="checkInternID"
                                  @blur="checkInternID"
                                  outlined
                                  v-model="form.ware_internalNo"/>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NUMERIC" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.lang_netPurchasePricePerUnit')"
                                  :rules="[v => !!v]"
                                  outlined
                                  type="text"
                                  @keypress="decimalNumbers($event, form.ware_buypriceNet)"
                                  v-model="form.ware_buypriceNet"/>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                  :label="this.$t('erp.langWareWarehouseQTY')"
                                  outlined
                                  v-model="form.createItemStorageQTY"/>
                  </v-col>

                  <v-col cols="12" md="4" sm="4">

                    <v-select
                        :items="units" item-text="unit_name" item-value="id"
                        :label="$t('erp.lang_purchaseUnit')"
                        outlined
                        v-model="form.unitOfPurchase"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" sm="4">
                    <v-select
                        :items="units" item-text="unit_name" item-value="id"
                        :label="$t('erp.lang_salesunit')"
                        outlined
                        v-model="form.sellUnit"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" sm="4">
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('erp.lang_saleUnitValue')"
                                  @focus="showTouchKeyboard"
                                  autocomplete="off"
                                  outlined
                                  required
                                  v-model="form.sellUnitValue"
                                  min="1"
                                  type="number"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" class="pt-3">
                    <v-flex md12 mr-2 ml-2>
                      <v-autocomplete
                          :label=" $t('erp.lang_ingredientGroups')"
                          :readonly="false"
                          newIngredientGroup :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          @click:append.stop="showIngredientGroup"
                          append-icon="add"
                          item-text="name"
                          :items="IngredientGroups"
                          item-value="uuid"
                          deletable-chips
                          outlined v-model="form.ingredientGroup"
                          dense
                      >
                        <template v-slot:prepend-inner>
                          <v-icon>arrow_drop_down</v-icon>
                        </template>
                        <template v-slot:append>
                          <v-menu
                              :close-on-content-click="false"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              @focus="showTouchKeyboard"
                              :nudge-width="200"
                              id="menu_create"
                              max-width="304"
                              offset-y
                              transition="slide-x-transition"
                              v-model="showIngredientCreate"
                              z-index="10"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn @click="showIngredientGroup" color="primary" class="pt-0"
                                     fab
                                     id="icon-wiregroup-add" small
                                     v-on="on">
                                <v-icon>add</v-icon>
                              </v-btn>
                            </template>
                            <v-card outlined>
                              <v-row align="center"
                                     class="dropdown-menu-header"
                                     justify="center">
                                <div class="dropdown-menu-header-inner bg-primary"
                                >
                                  <div class="menu-header-image dd-header-bg-1"></div>
                                  <div class="menu-header-content text-center pa-3 ma-0"
                                  >
                                    <div class="text-center"><h5
                                        class="menu-header-title ">
                                      {{ $t('erp.lang_ingredientGroupsAdd') }}</h5>
                                      <span class="mx-auto text-center">
                                           {{ $t('erp.lang_addIngredientGroupText') }}
                                            </span>
                                    </div>
                                  </div>
                                </div>
                              </v-row>
                              <div class="scroll-area-xl pa-2">
                                <v-form class="ma-0 pa-0"
                                        lazy-validation
                                        onsubmit="return false"
                                        ref="garnishGroupFrom">

                                  <v-row justify-md="center">
                                    <v-col cols="12">
                                      <v-text-field v-model="newIngredientGroup.name"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                    :label="$t('erp.lang_ingredientGroupName')"
                                                    autocomplete="off"
                                                    :rules="[v=>!!v && v.length>0 || $t('generic.lang_requiredField')]"
                                                    outlined
                                                    required
                                                    @focus="showTouchKeyboard"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <fontawesome-picker :fieldLabel="$t('generic.lang_icon')"
                                                          v-model="newIngredientGroup.icon"/>
                                    </v-col>
                                    <v-col cols="12">
                                      <div class="widget-content text-left pa-0">
                                                                        <span class="font-weight-medium">{{
                                                                            $t('erp.lang_warecreate_color')
                                                                          }}:</span>
                                        <swatches
                                            v-model="newIngredientGroup.color" inline
                                            background-color="transparent"
                                        ></swatches>
                                      </div>
                                    </v-col>
                                  </v-row>

                                  <v-divider class="pa-0 ma-0"/>
                                  <div class="text-center">
                                    <button @click="showIngredientCreate = false"
                                            class="btn btn-danger ma-2">
                                      {{ $t('generic.lang_cancel') }}
                                    </button>

                                    <v-btn color="primary"
                                           @click="createIngredientGroups"
                                           :loading="loadingCreatIngredientGroup"
                                           :disabled="newIngredientGroup.name===''||newIngredientGroup.name===null">
                                      {{ $t('generic.lang_create') }}
                                    </v-btn>
                                  </div>
                                </v-form>
                              </div>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0">
                    <strong>{{ $t('erp.mealType') }}</strong><br>
                    <v-radio-group class="mx-auto" v-if="form.gastroFoodType" row
                                   v-model="form.gastroFoodType">
                      <v-radio
                          :key="0"
                          :value="1"
                      >
                        <template slot="label">
                          <font-awesome-icon :icon="['fal', 'cocktail']" size="3x"/>
                        </template>
                      </v-radio>

                      <v-radio
                          :key="1"
                          :value="2"
                      >
                        <template slot="label">
                          <font-awesome-icon :icon="['fal', 'meat']" size="3x"/>
                        </template>
                      </v-radio>

                      <v-radio
                          :key="2"
                          :label="`Radio`"
                          :value="3"
                      >
                        <template slot="label">
                          <font-awesome-icon :icon="['fal', 'gifts']" size="3x"/>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12"  md="4" sm="6">
                    <v-checkbox
                        v-model="form.is_IngredientComponent"
                        :label="$t('erp.lang_ingredientComponent')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-checkbox :label="this.$t('erp.lang_dishOfTheDayControl')"
                                v-model="form.isDailyGoods" true-value="1" false-value="0"/>
                  </v-col>

                </v-row>
              </v-form>
            </v-tab-item>


            <v-tab-item>
              <imagecropper ref="ingredientImage" v-model="form.productImage"/>
            </v-tab-item>

            <v-tab-item class="pa-2">
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field :label="this.$t('erp.lang_warecreate_biozertno')"

                                outlined
                                v-model="form.biozertnr"/>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field :label="this.$t('erp.lang_warecreate_biotext')"

                                outlined
                                v-model="form.BioText"/>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-checkbox :label="this.$t('erp.lang_warecreate_bioware')" class="ma-0"
                              v-model="form.bio_yes_no" value="1"/>
                </v-col>

                <v-col class="ma-0 pa-0" cols="12">
                  <v-divider class="ma-0 pa-0"/>
                </v-col>

                <v-col cols="12">
                  <v-expansion-panels focusable mandatory>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>{{ this.$t('erp.lang_Zusatzstoffe') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>

                        <v-row>
                          <v-col :key="i" cols="12" md="4"
                                 v-for="(elt , i) in this.zusaetze">
                            <v-switch :label="elt[1]" :value="elt[0]"
                                      v-model="form.zusaetze"/>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- 2 -->
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>{{ this.$t('erp.lang_Allergene') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col :key="i" cols="12" md="4"
                                 v-for="(elt , i) in this.allergene">
                            <v-switch :label="elt[1]" :value="elt[0]"
                                      v-model="form.allergene"/>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-tab-item>

          </v-tabs-items>
        </v-form>
      </v-card-text>

      <v-card-actions class="text-right">
        <v-spacer/>
        <v-btn :disabled="this.tabs == 0" @click="--tabs" class="ma-1" color="error" icon>
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-btn :disabled="loading || loadingGO" :loading="loading" @click="(gotoTo = false) &  create()"
               class="ma-1" color="primary">
          {{ $t('generic.lang_add') }}
        </v-btn>
        <v-btn :disabled="loadingGO || loading" :loading="loadingGO" @click="(gotoTo = true) & create()"
               class="ma-1"
               color="success">{{ $t('generic.lang_next') }}
        </v-btn>

        <v-btn :disabled="this.tabs == 2" @click="++tabs" class="ma-1" color="primary" icon>
          <v-icon>arrow_forward</v-icon>
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                            :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"
                            id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
      </div>
    </v-card>
  </v-container>
</template>

<script>

import {library} from '@fortawesome/fontawesome-svg-core';
import {faBarcode, faCocktail, faGifts, faMeat} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
//configs
import {ENDPOINTS} from "../../../../config";
import Imagecropper from "../../../common/imagecropper";
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns";
import Swatches from 'vue-swatches';
import FontawesomePicker from "@/components/common/iconPicker";

library.add(
    faCocktail, faMeat, faGifts, faBarcode
);
export default {
  name: "IngredientCreateComponent",
  components: {
    Imagecropper,
    Swatches,
    FontawesomePicker,
    'font-awesome-icon': FontAwesomeIcon
  },
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      showIngredientCreate: false,
      loadingCreatIngredientGroup: false,
      gotoTo: false,
      loadingGO: false,
      loading: false,
      loadingEAN: false,
      loadingCheck: false,
      duplicatedEAN: false,
      loadingCheckInternID: false,
      duplicatedInternID: false,
      IngredientGroups: [],
      units: [],
      tabs: 0,
      form: {
        gastroFoodType: 1,
        allergene: [],
        zusaetze: [],
        IngredientGroups: null,
        is_IngredientComponent: false,
        unitOfPurchase: null,
        sellUnit: null,
        sellUnitValue: null,
        productImage: '',
      },
      newIngredientGroup: {
        name: null,
        color: null,
        icon: null
      },
      zusaetze: null,
      allergene: null
    }
  },
  methods: {
    filteredUnits() {
      if (!this.units || !this.units.length) {
        return [];
      }
      let filtered = [];
      filtered = this.units.map(unit => {
        if (!unit && unit.unit_name.length === 0)
          return;

        if (unit.unit_name.includes("lang_"))
          unit.unit_name = this.$t('erp.' + unit.unit_name);

        return unit;
      })
      return filtered;
    },
    getUnits() {
      this.axios.post(ENDPOINTS.ERP.UNITS.GET).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          this.units = res.data.units;
          this.units = this.filteredUnits();
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + this.$t('erp.lang_whileGettingUnits'),
          color: "error"
        });
      });
    },
    showIngredientGroup() {
      this.showIngredientCreate = true
    },
    getIngredientGroups() {
      this.axios.post(ENDPOINTS.ERP.INGRREDIENTGROUP.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.IngredientGroups = res.data.groups;


        }
      }).catch(err => {

      })
    },
    createIngredientGroups() {
      this.axios.post(ENDPOINTS.ERP.INGRREDIENTGROUP.CREATE, this.newIngredientGroup).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_ingredientGroupCreated'),
            color: "success"
          });
          this.getIngredientGroups();
          this.showIngredientCreate = false
          this.$refs.form.reset()
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: res.data.msg || res.data.status
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    create() {
      if (this.$refs.hasOwnProperty("ingredientImage") && this.$refs.ingredientImage?.getCroppedCanvas() !== null)
        this.$refs.ingredientImage.cropImage();

      if (!this.$refs.general.validate()) {
        this.tabs = 0;
        return;
      }

      this.loadingGO = this.gotoTo;
      this.loading = !this.loadingGO;

      let fd = new FormData();

      for (const key in this.form) {
        if (this.form.hasOwnProperty(key) && (this.form[key] === null || this.form[key] === undefined || this.form[key] === ""))
          delete this.form[key];
        else {
          if ((key === "allergene" || key === "zusaetze") && this.form[key].length > 0) {
            this.form[key].forEach(elt => {
              fd.append(key + '[]', elt)
            });
          } else
            fd.append(key, this.form[key])
        }
      }


      this.axios.post(ENDPOINTS.ERP.INGRREDIENT.CREATE, fd).then((res) => {
        if (parseInt(res.data.ingredientID) > 0) {
          this.$refs.general.reset();
          if (this.gotoTo) this.$router.push('/erp/baseData/ingredients/edit/' + res.data.ingredientID)
          else {
            this.tabs = 0;
            this.$refs.form.reset();
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_ActionSuccessful'),
              color: "success"
            });
          }
        } else {
          if (res.data.status === 'duplicateItem') {
            Events.$emit("showSnackbar", {
              message: this.$t('erp.lang_ean_idIsAlreadyAssigned'),
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingGO = this.loading = false;
      });
    },
    checkEAN() {
      this.loadingCheck = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEEAN, {
        //itemId: this.mainItemId,
        barcode: this.form.ware_ID
      }).then((res) => {
        this.duplicatedEAN = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheck = false;
      })
    },
    checkInternID() {
      this.loadingCheckInternID = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEINTERNALID, {
        //itemId: this.mainItemId,
        internalId: this.form.ware_internalNo
      }).then((res) => {
        this.duplicatedInternID = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheckInternID = false;
      })
    },
    createEAN() {
      this.loadingEAN = true;
      this.axios.post(ENDPOINTS.ERP.INGRREDIENT.CREATEAN).then((res) => {
        this.form.ware_ID = res.data[0]
      }).finally(() => {
        this.loadingEAN = false;
      });
    },
    loadData() {
      this.axios.post(ENDPOINTS.ERP.INGRREDIENT.GERCHECKBOXES).then((res) => {
        this.zusaetze = res.data.additivesArr;
        this.allergene = res.data.allergensArr;

        /* PRECHECK REQUIRED EAN AND INTERNAL ID CHECK */
        this.checkEAN();
        this.checkInternID();

      });
    },
    decimalNumbers(evt, val) {
      if(isNaN(evt.key) && evt.key !== '.' && evt.key !== ',' && evt.key !== '-' && evt.key !== 'Backspace') {
        evt.preventDefault();
      } else {
        if ((evt.key == '-' && val.match(/-/g) || []).length > 1 || (evt.key == '.' && val.indexOf(".") > -1)) {
          evt.preventDefault();
        }
      }
    },
  },
  mounted() {
    this.getIngredientGroups();
    this.loadData();
    this.getUnits();

  }
}
</script>
